import * as React from 'react';
import {
    Flex,
    Box,
    Link as ExternalLink,
    Text,
    Button,
} from '@chakra-ui/react';

import AppContainer from '../components/Layout/AppContainer';
import Layout from '../components/Layout/Layout';
import Section from '../components/Layout/Section';
import Mission from '../content/containers/Mission';
import theme from '../theme/theme';
import CoverHeading from '../components/cover/CoverHeading';
import AboutBlock from '../static/pages/AboutBlock';
import SocialConversion from '../components/cta/SocialConversion';

const _content = {
    subtitle: 'Open Positions',
    title: 'team@quant.one',
    text: 'Contact us to learn more about our code-based interviews and potential growth opportunities for the future.',
    content: [
        {
            title: 'Data Scientist - International Pool',
            text: "We're looking for entry-level Data Scientists with 1-2 years of experience in crafting models using C# libraries like TorchSharp or TensorFlow.NET for Text Analytics, HuggingFace Transformers, or Computer Vision use cases. International applicants accepted outside the following countries only: USA, CA, UK, AU, NZ.",
            subtitle: 'Remote - Contract - International',
        },
        {
            title: 'Front-End Developer - International Pool',
            text: 'Do you love to build front-end web apps in React.js, TypeScript, Mobx, and popular UI/UX libraries like Chakra UI, Ant Design, Framer Motion? Do you love working remotely but are hungry to grow with exciting projects? This is the role for you. Candidates must have 1-2 years of experience, share their portfolio and/or participate in a live coding challenge. International applicants accepted outside the following countries only: USA, CA, UK, AU, NZ.',
            subtitle: 'Remote - Contract - International',
        },
        {
            title: 'General Application Pool',
            text: "Submit your resume to stay in touch for future opportunities, we'll contact you if the fit is right for the tech stack and growth team we're building.",
            subtitle: 'Remote - Contract',
        },
    ],
};

class Contact extends React.Component {
    private CoverContent() {
        const { id, slug, header } = AboutBlock;

        return (
            <CoverHeading id={id} slug={slug} header={header} containers={[]} />
        );
    }

    public render() {
        return (
            <AppContainer>
                <Layout cover={<this.CoverContent />}>
                    <Section>
                        <Mission />
                    </Section>
                    <Section>
                        <Box w={'100%'}>
                            <Text fontSize={'xl'}>{_content.subtitle}</Text>
                            <Text fontSize={['2xl', '4xl']} color={'crimson'}>
                                {_content.title}
                            </Text>
                        </Box>
                        <Flex w={['100%', '75%', '66%']} flexWrap={'wrap'}>
                            {_content.content.map((x, k) => {
                                return (
                                    <Box
                                        key={k}
                                        color={'white'}
                                        my={[3, 5]}
                                        p={[5, 7]}
                                        bg={theme.colors.brand.greys.black97}
                                        borderRadius={'xl'}
                                    >
                                        <Text fontSize={'md'} color={'crimson'}>
                                            {x.subtitle}
                                        </Text>
                                        <Text my={2} fontSize={'2xl'}>
                                            {x.title}
                                        </Text>
                                        <Text my={3}>{x.text}</Text>
                                        <ExternalLink
                                            href={`mailto:${_content.title}`}
                                            py={5}
                                        >
                                            <Button colorScheme={'red'}>
                                                {'Apply'}
                                            </Button>
                                        </ExternalLink>
                                    </Box>
                                );
                            })}
                        </Flex>
                    </Section>
                    <Section>
                        <SocialConversion />
                    </Section>
                </Layout>
            </AppContainer>
        );
    }
}

export default Contact;
