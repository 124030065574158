import * as React from 'react';
import { Flex, Box, Spacer, Text } from '@chakra-ui/react';
import { IBlockProps } from '../../types/IBlockProps';

import StringProcess from '../design/StringProcess';
import Section from '../../components/Layout/Section';
import theme from '../../theme/theme';

interface ICoverHeadingProps extends IBlockProps {}

class CoverHeading extends React.Component<ICoverHeadingProps> {
    public render() {
        const { header } = this.props;
        const { title, subtitle, text } = header?.content[0] ?? {};

        return (
            <Section>
                <Flex flexWrap={'wrap'} alignItems={'center'} my={[3, 5, 7]}>
                    <Box
                        width={['100%', '75%']}
                        textAlign={'center'}
                        my={[5, 7, 12]}
                        mx={'auto'}
                        color={'white'}
                    >
                        {subtitle && (
                            <Text
                                px={5}
                                py={2}
                                mb={[3, 5]}
                                w={'auto'}
                                sx={{
                                    fontSize: 'sm',
                                    fontWeight: 700,
                                    textTransform: 'uppercase',
                                    bg: theme.colors.brand.alpha.black.alpha7,
                                    color: 'crimson',
                                    borderRadius: 'full',
                                    display: 'inline-flex',
                                }}
                            >
                                {subtitle}
                            </Text>
                        )}
                        <Text
                            fontSize={['3xl', '4xl']}
                            fontWeight={600}
                            lineHeight={1.25}
                        >
                            <StringProcess>{title}</StringProcess>
                        </Text>
                        <Box py={[2, 3]} />
                        <Text
                            fontSize={['lg', 'xl']}
                            fontWeight={'medium'}
                            lineHeight={1.5}
                            px={[3, 12]}
                        >
                            <StringProcess>{text}</StringProcess>
                        </Text>
                    </Box>
                </Flex>
            </Section>
        );
    }
}

export default CoverHeading;
