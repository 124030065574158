import { IBlockProps } from "../../types/IBlockProps";

const AboutBlock: IBlockProps = {
    id: "",
    slug: "block:pages:about",
    header: {
        id: "",
        slug: "header:pages:about:title",
        content: [
            {
                id: "1",
                slug: "content:about:title",
                title: "Scale-ready %AI% for growth leaders.",
                text: "We bridge the gap from Cloud to Edge, from analysts to coders, we build AI so you can focus on delivering the best experiences and insights for your team and customers. We are Quant ONE.",
            },
        ],
    },
    containers: [],
};

export default AboutBlock;
