import * as React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import StringProcess from '../../components/design/StringProcess';

const content = {
    id: '',
    slug: '',
    title: '%Our% Mission',
    text: "We're focused on you, the **data driven** growth leader that pushes boundaries to make impact a reality everyday. Production ready **AI** is the new frontier, we believe in crafting turn-key models, to put AI to work for you, the team you'd love to empower, partners that you'd love to work with, and a customer-first mission you'd want to be part of. **Join us.**",
};

class Mission extends React.Component {
    public render() {
        return (
            <Flex w={'100%'} my={[5, 7, 12, 16]} color={'white'}>
                <Box w={['100%', '25%']}>
                    <Text fontSize={['2xl', '4xl']} fontWeight={700}>
                        <StringProcess>{content.title}</StringProcess>
                    </Text>
                </Box>
                <Box w={['100%', '75%']} px={[3, 5, 7, 12]}>
                    <Text fontSize={['sm', 'md']} lineHeight={2}>
                        <StringProcess>{content.text}</StringProcess>
                    </Text>
                </Box>
            </Flex>
        );
    }
}

export default Mission;
