import * as React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import theme from '../../theme/theme';

interface IAppContainerProps {
    children: React.ReactNode | React.ReactElement;
}

/**
 * @class AppContainer default wrapper for nested page components
 */
class AppContainer extends React.Component<IAppContainerProps> {
    public render() {
        return (
            <ChakraProvider theme={theme}>
                <ColorModeScript initialColorMode={'dark'} />
                {this.props.children}
            </ChakraProvider>
        );
    }
}

export default AppContainer;
