import * as React from 'react';
import { Flex, Box, Text, ButtonGroup, IconButton } from '@chakra-ui/react';
import { FaLinkedin, FaGithub, FaDev, FaTwitter } from 'react-icons/fa';

import Metadata from '../SEO/Metadata';
import StringProcess from '../design/StringProcess';

// TODO: refactor with const content: IContentProps = ...
class SocialConversion extends React.Component {
    public render() {
        const { social } = Metadata;

        return (
            <Flex
                flexWrap={'wrap'}
                my={[5, 7, 12]}
                mx={'auto'}
                textAlign={'center'}
                color={'white'}
            >
                <Box mx={'auto'}>
                    <Text
                        fontSize={['xl', '2xl']}
                        fontWeight={'bold'}
                        textAlign={'center'}
                        mb={[5, 7]}
                    >
                        <StringProcess>
                            {'Check out our %insights% & %dev tips% on social'}
                        </StringProcess>
                    </Text>
                    <ButtonGroup
                        color={'white'}
                        variant={'unstyled'}
                        size={'xl'}
                        sx={{
                            '*': {
                                m: 4,
                                _hover: {
                                    bg: 'gray.800',
                                },
                            },
                        }}
                    >
                        <IconButton
                            as='a'
                            href={social.linkedin}
                            aria-label='Linkedin'
                            icon={<FaLinkedin size={36} />}
                            p={5}
                        />
                        <IconButton
                            as='a'
                            href={social.github}
                            aria-label='Github'
                            icon={<FaGithub size={36} />}
                            p={5}
                        />
                        <IconButton
                            as='a'
                            href={social.devto}
                            aria-label='DEV'
                            icon={<FaDev size={36} />}
                            p={5}
                        />
                        <IconButton
                            as='a'
                            href={social.twitter}
                            aria-label='twitter'
                            icon={<FaTwitter size={36} />}
                            p={5}
                        />
                    </ButtonGroup>
                </Box>
            </Flex>
        );
    }
}

export default SocialConversion;
